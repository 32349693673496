.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.w-80{
  width: 80% !important;
}

.cursor-pointer{
  cursor: pointer !important;
}

.badge-pill{
  text-decoration: none !important;
}

.badge-pill:hover{
  color: #ffffff;
  text-decoration: none !important;
}

.fw-semibold{
  font-weight: 600 !important;
}

/* agar mencoret */
.strike {
  text-decoration: line-through solid #e4213e;
}

.unstrike {
  text-decoration: none !important;
}

/* text-sm */
.text-sm {
  font-size: 0.875rem !important;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.column .d-flex {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: auto !important;
}

.whats-app {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 3px 4px 3px #ddd;
  right: 40px;
  z-index: 100;
}

.timeline {
  border-left: 3px solid #10B981;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0 auto;
  letter-spacing: 0.2px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px;
  list-style: none;
  text-align: left;
}

@media (max-width: 767px) {
  .timeline {
      max-width: 98%;
      padding: 25px;
  }
}

.timeline h1 {
  font-weight: 300;
  font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
}

.timeline .event {
  border-bottom: 1px dashed #e8ebf1;
  padding-bottom: 10px;
  margin-bottom: 10px;
  position: relative;
}

@media (max-width: 767px) {
  .timeline .event {
      padding-top: 30px;
  }
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}

@media (max-width: 767px) {
  .timeline .event:before {
      left: 0px;
      text-align: left;
  }
}

.timeline .event:after {
  -webkit-box-shadow: 0 0 0 3px #10B981;
  box-shadow: 0 0 0 3px #10B981;
  left: -55.8px;
  background: #fff;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  content: "";
  top: 5px;
}

@media (max-width: 767px) {
  .timeline .event:after {
      left: -31.8px;
  }
}

.rtl .timeline {
  border-left: 0;
  text-align: right;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: 3px solid #727cf5;
}

.rtl .timeline .event::before {
  left: 0;
  right: -170px;
}

.rtl .timeline .event::after {
  left: 0;
  right: -55.8px;
}

/* make rounded-lg */
.rounded-lg {
  border-radius: 0.6rem !important;
}

/* buatin card hover */
.card:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2) !important; 
  transition: 0.3s !important;
  cursor: pointer !important;
}

.custom-modal .modal-content {
  border-radius: 15px !important; /* Atur nilai sesuai kebutuhan Anda */
  border: none !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 600px) {
  .whats-app {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 10px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 3px 4px 3px #ddd;
    right: 20px;
    z-index: 100;
  }

  .whats-app .pt-2 {
    padding-top: 0px !important;
    width: 75%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
